body{
    #root{
        section{
            header{
                z-index: 99;
                width: 100%;
                top:0;
                position: sticky;
                nav{
                    figure{
                        height: 70px;
                        margin: 10px 0px;
                    }
                    img{
                        width: 100%;
                        max-width: 100%;
                        border-radius: 10%;
                        object-fit: cover;
                        height: 100%;
                    }
                    background-color: #333B50;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    a{text-decoration: none;}
                    p{
                        font-family: 'djr_regular';
                        color: #8993A1;
                        font-size: 20px;
                    }
                    p:hover{
                        color: #2965FF;
                        transition-duration: 0.5s;
                        cursor: pointer;
                    }
                }
                
            }
        }
    }
}
@media  only screen and (max-width:700px){
    body{
        #root{
            section{
                header{
                    nav{
                        justify-content: space-around;
                        padding:10px 0;
                        figure{
                            height: 30px;
                           
                            img{
                                width: 100%;
                                height: 100%;
                                border-radius: 10%;
                            }
                        }
                        p{
                            font-size: 10px;
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
} 
@font-face {
    font-family: 'djr_regular';
    src: url('../fonts/FormaDJRBanner-Regular-Testing.woff') format('woff');
}