body{
    #root{
        section{
            .profil{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                max-width: 1400px;
                margin: auto;
                text-align: center;
                color: #E6E6E6;
                font-family: 'djr_regular';
                font-size: 30px;
                h1{
                    font-family: 'djr_bold';
                    font-size: 30px;
                }
            }
        }
    }
}
@media only screen and (max-width:700px){
    body{
        #root{
            section{
                .profil{
                    p{
                        font-size: 22px;
                    }
                }
            }
        }
    }
}
@font-face {
    font-family: 'djr_regular';
    src: url('../fonts/FormaDJRBanner-Regular-Testing.woff') format('woff');
}