body{
    #root{
        section{
            .download{
                h1{
                    font-size: 30px;
                    font-family: 'djr_bold';
                    color:#E6E6E6;
                }
                max-width: 1400px;
                margin: auto;
                background-color: #333B50;
                display: flex;
                flex-direction: column;
                align-items: center;
                .myCV{
                    h2{
                        color: #E6E6E6;
                        font-family: 'djr_bold';
                    }
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    background-color: #8993A1;
                    border-radius: 10px;
                    width: 50%;
                }
            }
        }
    }
}
@media only screen and (max-width:700px){
    body{
        #root{
            section{
                .download{
                    h1{
                        font-size: 25px;
                    }
                    .myCV{
                        width: 70%;
                    }
                }
            }
        }
    }
}
@font-face {
    font-family: djr_bold;
    src: url('../fonts/FormaDJRBanner-Bold-Testing.woff');
}