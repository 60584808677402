body{
    #root{
        section{
            .swiper{
                display: none;
            color: #E6E6E6;
            text-align: center;
            font-size: 25px;
            font-family: 'djr_bold';
                .mySwiper{
                    text-align: center;
                    color: black;
                    .swiper-button-prev{
                        color: #333B50;
                        left: 40px;
                    }
                    .swiper-button-next{
                        color: #333B50;
                        right: 40px;
                    }
                        .techno_swiper{
                            background-color: #8993A1;
                            border-radius: 10px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: space-between;
                            width: 90%;
                            margin: auto;
                            height: 300px;
                            color: #E6E6E6;
                            font-size: 18px;
                            font-family: 'djr_bold';
                            figure{
                                width: 100%;
                                img{
                                    width: 5em;
                                }
                            }
                            progress{
                                accent-color: #00D646;
                            }
                        }
                    
                    .swiper-pagination-bullet{
                        background-color: #333B50;
                    }
                }
            }
        }
    }
}
@font-face {
    font-family: djr_medium;
    src: url('../fonts/FormaDJRBanner-Medium-Testing.woff') format('woff');
}
@font-face {
    font-family: djr_extra_bold;
    src: url('../fonts/FormaDJRBanner-ExtraBold-Testing.woff') format('woff');
}
@font-face {
    font-family: djr_bold;
    src: url('../fonts/FormaDJRBanner-Bold-Testing.woff') format('woff');
}
@media only screen and (max-width:700px) {
    body{
        #root{
            section{
                .swiper{
                    display: block;
                }
            }
        }
    }
}