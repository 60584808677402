body{
    #root{
        section{
            .name{
                display: flex;
                max-width: 1400px;
                flex-direction: column;
                align-items: center;
                margin: auto;
                text-align: left;
                background-image: linear-gradient( to right ,rgba(0,0,0, 0.4),
                rgba(0,0,0, 0.4)), url('../assets/wallpaper.png');
                background-repeat: repeat;
                background-color: #cccc;
                background-position:center;
                background-size: cover;
                width: 100%;
            
               .intro{
                display: flex;
                flex-direction: column;
               }
                .green{
                    color: #2965FF;
                    text-align: left;
                    font-family: 'djr_extra_bold';
                    letter-spacing: 10px;
                    font-size: 80px;
                }
                h2{
                    color: #E6E6E6;
                    font-size: 40px;
                    font-family: 'djr_regular';
                    letter-spacing: 5px;
                    position: relative;
                    left: -10px;
                    top: -80px;
                }
            }
        }
    }
}
@media only screen and (max-width:700px){
    body{
        #root{
            section{
                .name{
                    .intro{
                        .green{
                            margin-left: 30px;
                            font-size: 40px;
                            position: relative;
                            top:0;
                        }
                    }
                    h2{
                        position: relative;
                        font-size: 20px;
                        margin-left: 40px;
                        color: #E6E6E6;
                        top:-50px;
                    }
                }
            }
        }
    }
}
@font-face {
    font-family: djr_extra_bold;
    src: url('../fonts/FormaDJRBanner-ExtraBold-Testing.woff') format('woff');
}
@font-face {
    font-family: 'djr_regular';
    src: url('../fonts/FormaDJRBanner-Regular-Testing.woff') format('woff');
}