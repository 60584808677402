html{
    scroll-behavior: smooth;
}
.for-max-width{
    background-color: #333B50;
    max-width: 1400px;
    margin: auto;
}
body{
    #root{
        section{
           
            .contact{
                max-width: 1400px;
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            h1{
                color: #E6E6E6;
                font-size: 30px;
                font-family: 'djr_bold';
            }
            .coordonnees{
                background-color:#8993A1;
                width: 50%;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                    .linkedin{
                        display: flex;
                        justify-content: center;
                        gap: 10px;
                        align-items: center;
                        width: 100%;
                        a{
                            text-decoration: none;
                            color:#E6E6E6;
                            font-family: 'djr_bold';
                        }
                    }
                    .mail{
                        display: flex;
                        justify-content: center;
                        gap: 10px;
                        align-items: center;
                        width: 100%;
                        a{
                            text-decoration: none;
                            color:#E6E6E6;
                            text-align: center;
                            font-family: 'djr_bold';
                        }
                    }
                    .phone{
                        display: flex;
                        justify-content: center;
                        gap: 10px;
                        align-items: center;
                        width: 100%;
                        a{
                            font-family: 'djr_bold';
                            text-decoration: none;
                            color:#E6E6E6;
                        }
                    }
            }
            }
        }
    }
}
@media only screen and (max-width:700px){
    body{
        #root{
            section{
                .contact{
                    h1{
                        font-size: 25px;
                    }
                    .coordonnees{
                        width: 70%;
                        a{
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
}
@font-face {
    font-family: djr_bold;
    src: url('../fonts/FormaDJRBanner-Bold-Testing.woff');
}
@font-face {
    font-family: 'djr_regular';
    src: url('../fonts/FormaDJRBanner-Regular-Testing.woff') format('woff');
}