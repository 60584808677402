body{
    #root{
        section{
            .timeline{
                background-color: #333B50;
                margin: auto;
                max-width: 1400px;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                .points{
                    .timelinecontent{
                        position: relative;
                        left: 100px;
                        top: 50px;
                        background-color: #8993A1;
                        padding: 0 10px;
                        border-radius: 10px;
                    }
                    .timepoint{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 46px;
                        width: 46px;
                        border-radius: 50px;
                        background-color: #2965FF;
                        position: relative;
                    }
                    .insidepoint{
                        height: 30px;
                        width: 30px;
                        border-radius: 50px;
                        background-color: #2965FF;
                    }
                    .insidewhitepoint{
                        height: 30px;
                        width: 30px;
                        border-radius: 50px;
                        background-color: #333B50;
                    }
                    .object{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                    .object::after{
                        content: '';
                        display: block;
                        width: 10px;
                        height: 100px;
                        background-color: #2965FF;
                    }
                }
                .right-text{
                    margin-left: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    p{
                        background-color: #8993A1;
                        padding: 5px 10px;
                        border-radius: 10px;
                        text-align: center;
                        position: relative;
                        color:black;
                        font-family: 'djr_regular';
                        top: 10px;
                        margin-bottom: 95px;
                        a{
                            text-decoration: none;
                            color:black;
                            font-family: 'djr_regular';
                        }
                    }
                    .hidden{
                        color: #333B50;
                        margin-bottom: 95px;
                        background-color: #333B50;
                        a{
                            color:#2965FF;
                        }
                    }
                }
                .left-text{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    p{
                        margin-right: 20px;
                        background-color: #8993A1;
                        padding: 5px 10px;
                        color:black;
                        font-family: 'djr_regular';
                        border-radius: 10px;
                        text-align: center;
                        position: relative;
                        top: 10px;
                        margin-bottom: 92px;
                        a{
                            text-decoration: none;
                            color:black;
                            font-family: 'djr_regular';
                        }
                    }
                    .hidden{
                        color: #333B50;
                        background-color: #333B50;
                        a{
                            color:#2965FF;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:700px){
    body{
        #root{
            section{
                .timeline{
                    .left-text{
                        display: none;
                    }
                    .right-text{
                        p{
                            margin-bottom: 85px;
                        }
                        .hidden{
                            background-color: #8993A1;
                            color: black;
                            font-family: 'djr_regular';
                        }
                    }
                }
            }
        }
    }
}
@font-face {
    font-family: 'djr_regular';
    src: url('../fonts/FormaDJRBanner-Regular-Testing.woff') format('woff');
}