body{
    #root{
        section{
            .skills{
                background-color: #333B50;
                max-width: 1400px;
                margin: auto;
                text-align: center;
                color: #E6E6E6;
                font-size: 30px;
                font-family: 'djr_bold';
                p{
                    color: #333B50;
                }
                .skillgrid{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    .techno{
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        flex-direction: column;
                        background-color: #8993A1;
                        border-radius: 10px;
                        flex-basis: 400px;
                        margin: 10px;
                        height: 200px;
                            progress{
                                accent-color: #00D646;
                            }
                        h1{
                           font-size: 18px;
                            color: #E6E6E6;
                            font-family: 'djr_bold';
                        }
                        figure{
                            img{
                                width: 20%;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width:700px){
    body{
        #root{
            section{
                .skills{
                    display: none;
                }
            }
        }
    }
}
@font-face {
    font-family: djr_medium;
    src: url('../fonts/FormaDJRBanner-Medium-Testing.woff') format('woff');
}
@font-face {
    font-family: djr_extra_bold;
    src: url('../fonts/FormaDJRBanner-ExtraBold-Testing.woff') format('woff');
}
@font-face {
    font-family: djr_bold;
    src: url('../fonts/FormaDJRBanner-Bold-Testing.woff') format('woff');
}